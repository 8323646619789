$media_xs: "(max-width: 575px)";
$media_sm_min: "(min-width: 576px)";
$media_sm: "(min-width: 576px) and (max-width: 767px)";
$media_sm_max: "(max-width: 767px)";
$media_md: "(min-width: 768px) and (max-width: 991px)";
$media_md_min: "(min-width: 768px)";
$media_md_max: "(max-width: 991px)";
$media_lg: "(min-width: 992px) and (max-width: 1199px)";
$media_lg_min: "(min-width: 992px)";
$media_lg_max: "(max-width: 1199px)";
$media_xl: "(min-width: 1200px)";
$media_xxl: "(min-width: 1600px)";


// Fonts
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700|PT+Serif:700');

// Theme variables
@import "variables";

// Bootstrap
@import "bootstrap/scss/bootstrap";

// Theme components
// @import "forms";
@import "buttons";
@import "nav";
@import "navbar";
@import "card";
@import "media";
@import "main";
@import "intro";
@import "footer";
@import "instagram";
@import "sidebar";
// @import "newsletter";
// @import "vimeo";
// @import "preview";


$color_ci: #6b303c;
$color_gold: #eae2bb;

header {
  .claim {
    background: #eee;
    color: #777;
    background: linear-gradient(180deg, #eee 90%, darken(#eee, 5%) 100%);
    text-align: center;
    font-size: 1.25em;
    font-weight: 300;
    padding: .5em 0;
    letter-spacing: -.025em;
    i {
      font-style: normal;
      @media #{$media_sm_max} {
        display: none;
      }
    }
    span {
      @media #{$media_sm_max} {
        display: block;
        font-size: .8em;
      }
    }
  }
}

.header-ci {
  background-color: $color_ci;
  background: linear-gradient(180deg, $color_ci 90%, darken($color_ci, 5%) 100%);
  color: #fff;
  overflow: hidden;
  position: relative;

  .logo {
    margin: 2em auto;
    display: block;
    width: 15em;
    overflow: hidden;

    @media #{$media_sm} {
      width: 12em;
      height: 6.1em;
      margin: 1.5em auto;
      svg {
        .podcast {
          display: none;
        }
      }
    }
    @media #{$media_xs} {
      width: 12em;
      height: 6.1em;
      margin: 1.5em auto;
      svg {
        .podcast {
          display: none;
        }
      }
    }

    svg {
      width: 100%;
      height: auto;
      display: block;
      &, path {
        fill: $color_gold;
      }
    }
  }

.options-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: .5em;
  padding-bottom: .5em;

  @media #{$media_sm_max} {
    justify-content: center;
    padding-top: 0;
  }
}

  .options {
    float: right;
    margin: 0 1em;
    display: flex;
    flex-direction: row;

    @media #{$media_md} {
      flex-direction: column;
    }

    .options-col {
      margin: 0 5px;
      @media #{$media_md} {
        margin: -5px 0;
      }
      @media #{$media_sm_max} {
        margin: 0;
      }
    }
    a {
      margin: 10px 0;
      font-size: 2.5em;
      color: $color_gold;
      text-decoration: none;
      display: block;
      border-radius: .25em;
      border: 1px solid $color_gold;
      width: 4.25em;
      overflow: hidden;
      height: 1.1em;

      @media #{$media_sm_max} {
        margin: 10px 5px;
        font-size: 2.5em;
      }

      @media #{$media_xs} {
        font-size: 2em;
      }

      svg {
        display: block;
        width: 100%;
        height: auto;
        &, path {
          fill: $color_gold;
        }
      }

      &:hover {
        background: #fff;
        svg {
          &, path {
            fill: $color_ci;
          }
        }
      }
    }

    @media #{$media_sm_max} {
      margin: 0 .5em;
      float: none;
      display: flex;
      justify-content: center;
    }

  }

  
}

.social-links {
  overflow: hidden;
  a {
    float: left;
    display: block;
    margin: 0 1.5em 0 0;
    svg {
      font-size: 2em;
      width: 1em;
      height: 1em;
      color: $color_ci;
    }
  }
}


.card a:not(.btn),
a,
a:link,
a:active,
a:hover,
a:visited {
  color: $color_ci;
}

.card-title,
h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    color: inherit;
  }
} 

.card-header {
  padding: 0;
  display: block;
  .date {
    float: right;
    display: inline-block;
    margin: 0 0 0 1em;
  }
  .card-title {
    margin: .25em 0 .5em;
    color: #212529;
  }
}

.card-body {
  padding: 1.5em 0;
}

i.icon {
  font-size: 1em;
  font-style: normal;
  margin: 0 .25em 0 0;
  svg {
    margin: -.2em 0 0;
    height: 1em;
    width: 1em;
    display: inline-block;
    &, path, rect {
      fill: currentColor;
    }
  }
}